import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavbarComponent from "../../Navbar";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge"; // Import Badge component for rendering the sources badges
import Spinner from "react-bootstrap/Spinner"; // Import Spinner component for loading indication
import Modal from "react-bootstrap/Modal"; // Import Modal component for confirmation dialog
import Button from "react-bootstrap/Button"; // Import Button component for modal actions
import ConfettiBoom from "react-confetti-boom"; // Import ConfettiBoom
import { useAuth } from "../../Authentication/AuthContext"; // Import useAuth from AuthContext

// Import styles
import "../../../styles/gpt_35/GPT_35_BASE.css";

// Import Lucide Icons
import {
  Send,
  User,
  Trash,
  Pencil,
  ChevronLeft,
  ChevronRight,
  BookOpenCheck,
  Heart,
  Bone,
  Apple,
  Dumbbell,
  HelpCircle,
} from "lucide-react";

// Import required images
import logo from "../../../images/Global/true-chat-anatomy-logo.png"; // Import the logo

import { marked } from "marked";

// Define all the conversational starters for the chatbot (Contains displayed prompt, actual elaborated prompt sent to LLM, and iconc)
const allStarters = {
  "Quiz me on heart anatomy": {
    value:
      "Can you provide five multiple choice questions with five options on how the heart supplies blood?",
    icon: <BookOpenCheck size={16} />,
  },
  "What is the difference between the 10th rib and 12th rib?": {
    value: "Can you explain the difference between the 10th rib and 12th rib?",
    icon: <Bone size={16} />,
  },
  "Where is the mitral valve auscultated?": {
    value:
      "Can you describe the anatomical location and landmarks on the thoracic wall for mitral valve auscultation?",
    icon: <Heart size={16} />,
  },
  "Why is the hepatic portal system crucial for the liver?": {
    value:
      "Can you explain why the hepatic portal system is crucial for nutrient metabolism in the liver?",
    icon: <Apple size={16} />,
  },
  "How do the tongue's muscles aid speech and swallowing?": {
    value:
      "How do the intrinsic and extrinsic muscles of the tongue work together to facilitate speech and swallowing?",
    icon: <Dumbbell size={16} />,
  },
};

// GPT_35_RAG component where users can interact with the Chat Anatomy chatbot
function GPT_35_RAG() {
  const [question, setQuestion] = useState(""); // State to store the user's current question
  const [chatHistory, setChatHistory] = useState([]); // State to store the chat history
  const [chatList, setChatList] = useState([]); // State to hold the list of chats
  const [isSending, setIsSending] = useState(false); // State to manage sending state for API calls
  const [isStreaming, setIsStreaming] = useState(false); // State to manage streaming state
  const [tempBufferState, setTempBufferState] = useState(""); // New state for tempBuffer to temporarily store streamed messages
  const [showConfetti, setShowConfetti] = useState(false); // State to manage confetti
  const [triggerSend, setTriggerSend] = useState(false); // State to trigger sending of conversational starters prompt (for conversational starters)
  const [randomizedStarters, setRandomizedStarters] = useState([]); // State for storing randomized starters
  const [showInstructions, setShowInstructions] = useState(false); // State to manage instructions modal
  const chatHistoryRef = useRef(null); // Reference to the chat history for auto-scrolling
  const navigate = useNavigate(); // Hook to allow navigation
  const { chatUUID } = useParams(); // Extract the chatUUID from the URL
  const { username } = useAuth(); // Use the useAuth hook to get the current username
  // State for managing textarea rows
  const [textAreaRows, setTextAreaRows] = useState(1);

  // States for editing title of chat
  const [isEditing, setIsEditing] = useState(false); // State to manage edit mode
  const [editValue, setEditValue] = useState(""); // State to store the temporary input value
  const [editChatUUID, setEditChatUUID] = useState(null); // State to store the UUID of the chat being edited

  // State for managing left sidebar
  const [leftsidebarOpen, setLeftSidebarOpen] = useState(true);

  // State for managing the delete confirmation modal
  const [showModal, setShowModal] = useState(false);
  const [chatUUIDToDelete, setChatUUIDToDelete] = useState(null);

  // State for streaming responses
  const [streamedMessages, setStreamedMessages] = useState([]);

  // Set the model variable to gpt-35
  const model = "gpt-35";

  // Ref for the input element to focus on it when it appears
  const inputRef = useRef(null);

  // Function to handle the left sidebar toggle
  const toggleLeftSidebar = () => {
    setLeftSidebarOpen(!leftsidebarOpen);
  };

  // Function to fetch the chat list
  const fetchChatList = async () => {
    const apiEndpoint =
      "https://a6c0444llh.execute-api.ap-southeast-1.amazonaws.com/default/chat-anatomy-retrieve-all-chats";

    // Call API to get the list of chats for the current user
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, model }), // Send the current username and model in the request
      });

      // Process the response
      if (response.ok) {
        const data = await response.json();
        setChatList(data.chats); // Update the chat list state
      } else {
        // Handle errors
        console.error("Error fetching chat list:", await response.text());
      }
    } catch (error) {
      // Handle network errors
      console.error("Error fetching chat list:", error);
    }
  };

  // Function to fetch specific chat history based on the UUID
  const fetchChatHistory = async (uuid) => {
    const apiEndpoint =
      "https://42ua0tkhj1.execute-api.ap-southeast-1.amazonaws.com/default/chat-anatomy-sources-retrieve-chat-history";
    // Call API to get the chat history for the current chat session
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, uuid }), // Send the current username and chat UUID in the request
      });

      // Process the response
      if (response.ok) {
        const data = await response.json();
        setChatHistory(data); // Update the chat history state with the new data structure

        // Ensure the latest message is visible
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      } else {
        // Handle errors
        console.error("Error fetching chat history:", await response.text());
      }
    } catch (error) {
      // Handle network errors
      console.error("Error fetching chat history:", error);
    }
  };

  // Function to render sources badges for the assistant's messages
  const renderSourcesBadges = (sources) => {
    if (sources) {
      // Map over the sources and create a badge for each
      return Object.entries(sources).map(([source, percentage], index) => (
        <Badge key={index} bg="primary" className="mr-2">
          {source} | {percentage.toFixed(1)}%
        </Badge>
      ));
    } else {
      // If there are no sources, return a badge indicating so
      return <Badge bg="secondary">No sources used</Badge>;
    }
  };

  // // Delete chat by calling chat deletion API
  // const handleTrashClick = async (e, chatUUIDToDelete) => {
  //   e.stopPropagation(); // Prevent event bubbling
  //   const deleteChatApiEndpoint = 'https://kwc0e1soyc.execute-api.ap-southeast-1.amazonaws.com/default/chat-anatomy-delete-chat';

  //   try {
  //     const response = await fetch(deleteChatApiEndpoint, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ username, uuid: chatUUIDToDelete }), // Pass username and chat_UUID to API
  //     });

  //     if (response.ok) {
  //       await fetchChatList(); // Refresh chat list to reflect deletion
  //       if (chatUUIDToDelete === chatUUID) { // Use the `chatUUID` from the top level of component
  //         setChatHistory([]); // Clear chat history state
  //         navigate(`/gpt-3-5/rag`); // Navigate to base URL if current chat is deleted
  //       }
  //     } else {
  //       console.error('Error deleting chat:', await response.text());
  //     }
  //   } catch (error) {
  //     console.error('Error deleting chat:', error);
  //   }
  // };

  // Delete chat by calling chat deletion API
  const handleTrashClick = (e, chatUUIDToDelete) => {
    e.stopPropagation(); // Prevent event bubbling
    setShowModal(true); // Show the modal
    setChatUUIDToDelete(chatUUIDToDelete); // Set the chat UUID to delete
  };

  const confirmDeleteChat = async () => {
    const deleteChatApiEndpoint =
      "https://kwc0e1soyc.execute-api.ap-southeast-1.amazonaws.com/default/chat-anatomy-delete-chat";

    try {
      const response = await fetch(deleteChatApiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, uuid: chatUUIDToDelete }), // Pass username and chat_UUID to API
      });

      if (response.ok) {
        await fetchChatList(); // Refresh chat list to reflect deletion
        if (chatUUIDToDelete === chatUUID) {
          // Use the `chatUUID` from the top level of component
          setChatHistory([]); // Clear chat history state
          navigate(`/gpt-3-5/rag`); // Navigate to base URL if current chat is deleted
        }
        setShowModal(false); // Close the modal
      } else {
        console.error("Error deleting chat:", await response.text());
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false); // Close the modal without deleting
    setChatUUIDToDelete(null); // Clear the chat UUID to delete
  };

  // Function to handle the pencil icon click for editing chat title
  const handlePencilClick = (e, chatUUID) => {
    e.stopPropagation();
    setIsEditing(true);
    setEditChatUUID(chatUUID);
    const currentChat = chatList.find((chat) => chat.chat_UUID === chatUUID);
    setEditValue(currentChat?.chat_title || "");
  };

  // Function to handle the Enter key press in edit mode
  const handleEditKeyPress = (e) => {
    if (e.key === "Enter") {
      // Blur the input to trigger the handleEditFinish function
      e.preventDefault();
      handleEditFinish();
    }
  };

  // Function to handle the rename logic
  const handleEditFinish = async () => {
    if (editValue.trim() !== "") {
      await renameChat(editChatUUID, editValue); // Call the rename chat API
    }
    setIsEditing(false); // Exit edit mode
    setEditValue(""); // Reset edit value
    setEditChatUUID(null); // Reset the editing UUID
  };

  // Function to update the temporary input value
  const handleEditChange = (e) => {
    setEditValue(e.target.value);
  };

  // Function to rename a chat
  const renameChat = async (chatUUID, newName) => {
    const renameChatApiEndpoint =
      "https://vasrjcoz00.execute-api.ap-southeast-1.amazonaws.com/default/chat-anatomy-rename-chat";
    try {
      const response = await fetch(renameChatApiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          UUID: chatUUID,
          renamed_chat_name: newName,
        }), // Ensure the payload keys match the Lambda function's expected keys
      });

      if (response.ok) {
        await fetchChatList(); // Refresh chat list to reflect the new name
      } else {
        console.error("Error renaming chat:", await response.text());
      }
    } catch (error) {
      console.error("Error renaming chat:", error);
    }
  };

  // Function to render the messages from users and bots as required
  const renderChatMessages = () => {
    // Renders messages based on whether the chat is streaming or not
    if (isStreaming) {
      // If the chat is streaming, render the following messages
      return (
        <>
          {/* Renders past chat history messages */}
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`chat-message ${
                chat.role === "user" ? "user-message" : "bot-message"
              }`}
            >
              <div className="message-sender">
                {/* Renders the user icon messages */}
                {chat.role === "user" ? (
                  <>
                    <div className="user-icon">
                      <User color="white" size={18} />{" "}
                      {/* Assuming you have a User icon from Lucide */}
                    </div>
                    <strong>User</strong>
                  </>
                ) : (
                  <>
                    <img
                      src={logo}
                      alt="Anatbuddy Logo"
                      className="chat-logo-small"
                    />
                    <strong>Anatbuddy</strong>
                  </>
                )}
              </div>
              {/* Renders the assistant icon messages */}
              <div
                className="message-content"
                dangerouslySetInnerHTML={{ __html: chat.content }}
              ></div>
              {chat.role === "assistant" && (
                <>
                  <hr className="message-divider" /> {/* Horizontal row */}
                  <div className="sources-header">Sources used:</div>{" "}
                  {/* Sources header */}
                  <div className="sources-badges">
                    {renderSourcesBadges(chat.rag_sources)}
                  </div>
                </>
              )}
            </div>
          ))}
          {/* Renders the assistant icon */}
          <div className="chat-message bot-message">
            <div className="message-sender">
              <img
                src={logo}
                alt="Anatbuddy Logo"
                className="chat-logo-small"
              />
              <strong>Anatbuddy</strong>
            </div>
            {/* Renders the assistant streamed messages everytime streamedMessages is updated with new chunks */}
            <div className="message-content">
              {streamedMessages.map((message, index) => (
                <span
                  key={index}
                  dangerouslySetInnerHTML={{ __html: message }}
                ></span>
              ))}
            </div>
          </div>
        </>
      );
    }
    // If the chat is not streaming, render the following messages from the chat history taken from DynamoDB
    return (
      <>
        {chatHistory.map((chat, index) => (
          <div
            key={index}
            className={`chat-message ${
              chat.role === "user" ? "user-message" : "bot-message"
            }`}
          >
            <div className="message-sender">
              {chat.role === "user" ? (
                <>
                  <div className="user-icon">
                    <User color="white" size={18} />{" "}
                    {/* Assuming you have a User icon from Lucide */}
                  </div>
                  <strong>User</strong>
                </>
              ) : (
                <>
                  <img
                    src={logo}
                    alt="Anatbuddy Logo"
                    className="chat-logo-small"
                  />
                  <strong>Anatbuddy</strong>
                </>
              )}
            </div>
            <div
              className="message-content"
              dangerouslySetInnerHTML={{ __html: chat.content }}
            ></div>
            {/* <div className="message-content">{chat.content}</div> */}
            {chat.role === "assistant" && (
              <>
                <hr className="message-divider" /> {/* Horizontal row */}
                <div className="sources-header">Sources used:</div>{" "}
                {/* Sources header */}
                <div className="sources-badges">
                  {renderSourcesBadges(chat.rag_sources)}
                </div>
              </>
            )}
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    fetchChatList();
  }, [username]); // Re-fetch chat list when username changes

  useEffect(() => {
    if (chatUUID) {
      fetchChatHistory(chatUUID);
    }
  }, [chatUUID, username]); // Re-fetch
  // chat history when chatUUID or username changes

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value); // Update the question state with the new value
    // Calculate the number of lines to set textarea height dynamically
    const lineCount = e.target.value.split(/\r*\n/).length;
    setTextAreaRows(lineCount < 3 ? lineCount : 3); // Limit to 3 lines
  };

  const handleChatItemClick = (chatUUID) => {
    navigate(`/gpt-3-5/rag/${chatUUID}`); // Navigate to the route with the UUID
  };

  // Handle starter click
  const handleStarterClick = (prompt) => {
    setQuestion(prompt); // Set the selected starter prompt as the question
    setTriggerSend(true);
  };

  // Use effect to send the question to the API when triggerSend is set to true
  useEffect(() => {
    if (triggerSend && question !== "") {
      sendQuestionToAPI();
      setTriggerSend(false);
    }
  }, [triggerSend, question]);

  const sendQuestionToAPI = async () => {
    setIsSending(true); // Disable the button by setting isSending to true
    setStreamedMessages([]);
    if (!chatUUID) {
      // Current working functionURL of Anatbuddy create chat endpoint (Pinecone Vector Store)
      const createChatApiEndpoint = "https://kwjw7jizysigwm7n3cvgcpcpvq0zxshg.lambda-url.ap-southeast-1.on.aws/"
      // Current working functionURL of combined function if chat_UUID does not exist (Opensearch Serverless Vector Store)
      // const createChatApiEndpoint = "https://4kpx2fzl7g4la4qsc4ouvry5my0iktri.lambda-url.ap-southeast-1.on.aws/"
      // 2nd implementation of combined function if chat_UUID does not exist (Bryan's Lambda function)
      // const createChatApiEndpoint = "https://rlh3w46adddhnloedxp5vhn33a0wkywz.lambda-url.ap-southeast-1.on.aws/"
      // Initial implementation of the create chat API endpoint (Gautham's Lambda function)
      // const createChatApiEndpoint = 'https://p1sv4b4g4b.execute-api.ap-southeast-1.amazonaws.com/default/chat-anatomy-create-chat';
      try {
        const response = await fetch(createChatApiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, message: question, model }),
        });
        if (response.ok) {
          const data = await response.json();
          const newChatUUID = data.chat_session_uuid;
          const messages = data.messages;
          const azure_model_endpoint = data.azure_model_endpoint;
          const rag_sources = data.rag_sources;
          const chat_history = data.chat_history;

          navigate(`/gpt-3-5/rag/${newChatUUID}`);
          await fetchChatList();
          await fetchChatHistory(newChatUUID);
          const assistantResponse = await streamAssistantResponse(
            messages,
            azure_model_endpoint
          );

          // Convert the assistant response to lowercase and check for compliment words
          const lowerCaseResponse = assistantResponse.toLowerCase();
          if (
            lowerCaseResponse.includes("well done") ||
            lowerCaseResponse.includes("great job") ||
            lowerCaseResponse.includes("congratulations") ||
            lowerCaseResponse.includes("good job") ||
            lowerCaseResponse.includes("excellent") ||
            lowerCaseResponse.includes("fantastic") ||
            lowerCaseResponse.includes("wonderful") ||
            lowerCaseResponse.includes("amazing") ||
            lowerCaseResponse.includes("splendid") ||
            lowerCaseResponse.includes("nice job")
          ) {
            setShowConfetti(true); // Trigger confetti
          }

          await storeAssistantResponse_titleSummarised(
            username,
            newChatUUID,
            assistantResponse,
            rag_sources,
            chat_history
          );
          await fetchChatHistory(newChatUUID);
          await fetchChatList();
          randomStarters();
          // setAssistantResponse('');
        } else {
          console.error("Error creating chat:", await response.text());
          randomStarters();
        }
      } catch (error) {
        console.error("Error creating chat:", error);
        randomStarters();
      }
    } else {
      // Current working functionURL of Anatbuddy send question endpoint (Pinecone Vector Store)
      const azureGptApiEndpoint = 'https://e6wnxzcvpv5avqkfixopkyvdee0zwsfc.lambda-url.ap-southeast-1.on.aws/'
      // Current working functionURL of combined function if chat_UUID exists (Opensearch Serverless Vector Store)
      // const azureGptApiEndpoint = "https://rmzv53whdvr6umtacekf6fzmle0zoqha.lambda-url.ap-southeast-1.on.aws/"
      // 2nd implementation of combined function if chatUUID exists (Bryan's Lambda function)
      // const azureGptApiEndpoint = 'https://vv6gtmp5usw75yeaxltpummq3a0atdbv.lambda-url.ap-southeast-1.on.aws/';
      //Initial implementation of the Azure GPT API endpoint
      // const azureGptApiEndpoint = 'https://d5qxc3a1mb.execute-api.ap-southeast-1.amazonaws.com/default/chat-anatomy-azure-gpt-35';
      try {
        const response = await fetch(azureGptApiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            chat_UUID: chatUUID,
            message: question,
            model,
          }),
        });
        if (response.ok) {
          await fetchChatHistory(chatUUID);
          const data = await response.json();
          const messages = data.messages;
          const azure_model_endpoint = data.azure_model_endpoint;
          const rag_sources = data.rag_sources;
          const chat_history = data.chat_history;
          const assistantResponse = await streamAssistantResponse(
            messages,
            azure_model_endpoint
          );

          // Convert the assistant response to lowercase and check for compliment words
          const lowerCaseResponse = assistantResponse.toLowerCase();
          if (
            lowerCaseResponse.includes("well done") ||
            lowerCaseResponse.includes("great job") ||
            lowerCaseResponse.includes("congratulations") ||
            lowerCaseResponse.includes("good job") ||
            lowerCaseResponse.includes("excellent") ||
            lowerCaseResponse.includes("fantastic") ||
            lowerCaseResponse.includes("wonderful") ||
            lowerCaseResponse.includes("amazing") ||
            lowerCaseResponse.includes("splendid") ||
            lowerCaseResponse.includes("nice job")
          ) {
            setShowConfetti(true); // Trigger confetti
          }

          await storeAssistantResponse(
            username,
            chatUUID,
            assistantResponse,
            rag_sources,
            chat_history
          );
          await fetchChatHistory(chatUUID);
          await fetchChatList();
          randomStarters();
        } else {
          console.error(
            "Error sending question to API:",
            await response.text()
          );
          randomStarters();
        }
      } catch (error) {
        console.error("Error sending question to API:", error);
        randomStarters();
      }
    }
    setIsSending(false); // Re-enable the button by setting isSending to false after the API response
    setQuestion(""); // Clear the question input field after sending
  };

  // Sends the question when Enter is pressed, allows line breaks with Shift+Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendQuestionToAPI();
    }
  };

  // Function to randomize and update the starters
  const randomStarters = () => {
    const randomized = Object.entries(allStarters)
      .sort(() => 0.5 - Math.random())
      .slice(0, 4);
    setRandomizedStarters(randomized);
  };

  // Initial randomization of starters
  useEffect(() => {
    randomStarters();
  }, []);

  // Render flavour text when no chat is loaded
  const NoChatsPlaceholder = () => (
    <div className="no-chats-placeholder">
      <img src={logo} alt="Chat Anatomy Logo" className="no-chat-logo" />
      <p className="no-chat-text fs-4 mb-1">What are your anatomy questions today?</p>
      <p className="no-chat-description fs-6 fw-light">Anatbuddy is here to assist you with answering questions, explaining concepts,<br/> and checking your understanding of key concepts related to gross anatomy.</p>
      {!isStreaming && !isSending && (
        <div className="starter-buttons-row w-75 mt-2">
          <div className="row gx-3 justify-content-center">
            {randomizedStarters.map(([key], index) => {
              const starter = allStarters[key];
              return(
                <div className='col-12 col-sm-6 col-md-3 mb-2' key={index}>
                  <Button variant="outline-secondary" className="w-100" onClick={() => handleStarterClick(starter.value)}>
                    <div>
                      {starter.icon}
                      <span className='ms-2 mt-1'>{key}</span>
                    </div>
                  </Button>
                </div>
              )
            })}
          </div>
      </div>
      )}
    </div>
  );

  // Let users easily start a new chat
  const handleLogoClick = () => {
    setChatHistory([]); // Clear chat history state
    navigate("/gpt-3-5/rag"); // Navigate to the specified path
  };

  //Streaming of LLM response
  const streamAssistantResponse = async (messages, azure_model_endpoint) => {
    setIsStreaming(true);
    let accumulatedResponse = "";
    // Original stream endpoint
    const streamEndpoint =
      "https://cnnzlmepfxprzspxlpxoglen2a0medew.lambda-url.ap-southeast-1.on.aws/";
    // Test stream endpoint for proper formatting
    // const streamEndpoint = 'https://2vzzqszaompoeu2qyn2jcfkqoe0yelqg.lambda-url.ap-southeast-1.on.aws/';
    try {
      const response = await fetch(streamEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          azure_model_endpoint,
          messages,
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      // let tempBuffer = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        if (value) {
          const chunk = decoder.decode(value, { stream: true });

          // Split the chunk by <br> tag
          const parts = chunk.split("<br>");

          parts.forEach((part, index) => {
            if (index < parts.length - 1) {
              setTempBufferState((prevBuffer) => {
                const updatedBuffer = prevBuffer + part + "<br>";
                let markedBuffer = marked(updatedBuffer);
                // console.log(`Unmarked Buffer: ${markedBuffer}`)
                const santizedBuffer = markedBuffer.replace(
                  /<\/?(pre|code)>/g,
                  ""
                ); // Removes code and pre tags of the marked buffer
                markedBuffer = marked(santizedBuffer); // Parses the inner HTML of the santized buffer
                // console.log(`Marked Buffer: ${markedBuffer}`)  // This stage still contains <br> tag (unicode equivalence: &lt;br&gt;)
                markedBuffer = markedBuffer.replace(/&lt;br&gt;/g, ""); // Replace &lt;br&gt; with nothing
                // console.log(`New Marked Buffer: ${markedBuffer}`) // Log the new marked buffer (completely no br tags)
                setStreamedMessages((prevMessages) => [
                  ...prevMessages,
                  markedBuffer,
                ]);
                accumulatedResponse += markedBuffer;
                return ""; // Clear the tempBufferState after setting streamedMessages
              });
            } else {
              setTempBufferState((prevBuffer) => prevBuffer + part); // Accumulate the last part without <br>
            }
          });
        }
      }

      // At the end, if there's remaining content in tempBufferState, add it to streamedMessages
      setTempBufferState((prevBuffer) => {
        if (prevBuffer.length > 0) {
          let markedBuffer = marked(prevBuffer);
          // console.log(`Unmarked Buffer: ${markedBuffer}`)
          const santizedBuffer = markedBuffer.replace(/<\/?(pre|code)>/g, ""); // Removes code and pre tags of the marked buffer
          markedBuffer = marked(santizedBuffer); // Parses the inner HTML of the santized buffer
          // console.log(`Marked Buffer: ${markedBuffer}`) // This stage still contains <br> tag (unicode equivalence: &lt;br&gt;)
          markedBuffer = markedBuffer.replace(/&lt;br&gt;/g, ""); // Replace &lt;br&gt; with nothing
          // console.log(`New Marked Buffer: ${markedBuffer}`) // Log the new marked buffer (completely no br tags)
          setStreamedMessages((prevMessages) => [
            ...prevMessages,
            markedBuffer,
          ]);
          accumulatedResponse += markedBuffer;
        }
        return ""; // Clear tempBufferState
      });
      // Introduce a delay before returning the response
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(accumulatedResponse); // Return the accumulated response after delay
        }, 100); // Delay in milliseconds (e.g., 1000ms = 1 second)
      });
      // return accumulatedResponse;
    } catch (error) {
      console.error("Error streaming assistant response:", error);
    }
  };

  //Store assistant response and summarise chat title (if chat_UUID is not found)
  const storeAssistantResponse_titleSummarised = async (
    username,
    chat_session_uuid,
    assistantResponse,
    rag_sources,
    chat_history
  ) => {
    const storeResponseEndpoint =
      "https://hysodlotfigzyw7byndtegjwiy0sumky.lambda-url.ap-southeast-1.on.aws/";
    try {
      const response = await fetch(storeResponseEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          chat_session_uuid: chat_session_uuid,
          gpt_content: assistantResponse,
          rag_sources: rag_sources,
          chat_history: chat_history,
        }),
      });
      fetchChatList();
      if (!response.ok) {
        console.error(
          "Error storing assistant response:",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error storing assistant response:", error);
    } finally {
      setIsStreaming(false);
      // await fetchChatHistory(chatUUID); // Fetch complete chat history after streaming
    }
  };

  //Store assistant response (if chat_UUID is found)
  const storeAssistantResponse = async (
    username,
    chat_session_uuid,
    assistantResponse,
    rag_sources,
    chat_history
  ) => {
    const storeResponseEndpoint =
      "https://bkppebgw4awlf57c6pmje4jghe0ngleo.lambda-url.ap-southeast-1.on.aws/";
    try {
      const response = await fetch(storeResponseEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          chat_session_uuid: chat_session_uuid,
          gpt_content: assistantResponse,
          rag_sources: rag_sources,
          chat_history: chat_history,
        }),
      });
      fetchChatList();
      if (!response.ok) {
        console.error(
          "Error storing assistant response:",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error storing assistant response:", error);
    } finally {
      setIsStreaming(false);
      // await fetchChatHistory(chatUUID); // Fetch complete chat history after streaming
    }
  };

  const handleShowInstructions = () => setShowInstructions(true); // Function to show instructions
  const handleCloseInstructions = () => setShowInstructions(false); // Function to close instructions

  // Confetti effect management
  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => setShowConfetti(false), 5000); // Confetti duration of 5 seconds
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  return (
    <div>
      <NavbarComponent toggleLeftSidebar={toggleLeftSidebar} leftsidebarOpen={leftsidebarOpen}/>
      <Container fluid className="p-0 d-flex flex-row">
        <Col md={2} className={`chat-list-sidebar ${leftsidebarOpen ? 'open': 'collapsed d-none'} p-0 d-flex flex-column justify-content-start`}>
          <div className="chat-header">
            <div className="chat-header-content" onClick={handleLogoClick}>
              <img src={logo} alt="Chat Anatomy Logo" className="chat-logo" />
              <p className='chat-title'>Anatbuddy</p>
            </div>
            <hr className="title-separator" />
            <div className="your-chats">Your Chats</div>
          </div>
          {/* Padding wrapper for the chat list */}
          <div className="list-group-padding flex-grow-1">
            <ListGroup>
            {chatList.map((chat, index) => (
              <ListGroup.Item
                key={index}
                action
                onClick={() => handleChatItemClick(chat.chat_UUID)}
                className={`chat-list-item ${isEditing && editChatUUID === chat.chat_UUID ? 'editing' : ''}`}
              >
                {isEditing && editChatUUID === chat.chat_UUID ? (
                  <input
                    ref={inputRef}
                    type="text"
                    value={editValue}
                    onChange={handleEditChange}
                    onKeyPress={handleEditKeyPress}
                    className="edit-input"
                    autoFocus // Auto-focus the input when editing
                    onBlur={() => handleEditFinish()} // Call handleEditFinish when input loses focus
                  />
                ) : (
                    <>
                      {/* Display chat title if available, otherwise display UUID */}
                      {chat.chat_title || chat.chat_UUID}
                      <div className="chat-item-icons">
                        <Trash
                          size={20}
                          className="chat-icon trash-icon"
                          onClick={(e) => handleTrashClick(e, chat.chat_UUID)}
                        />
                        <Pencil
                          size={20}
                          className="chat-icon pencil-icon"
                          onClick={(e) => handlePencilClick(e, chat.chat_UUID)}
                        />
                      </div>
                    </>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>

          <div className='help-button-container'>
            <Button 
              variant="link" 
              onClick={handleShowInstructions} 
              className="help-button" 
              // style={{ position: 'absolute', bottom: '10px', left: '10px' }}
            >
              <HelpCircle size={20} color="#fff" />
            </Button>
          </div>
        </Col>
          {/* md={sidebarOpen ? 7:10} */}
          
          <Col 
            className={`chat-column ${chatHistory.length === 0 ? '' : 'p-3'}`} 
            xs={12} 
            md={leftsidebarOpen ? 10 : 12}
          >
            <div className="model-label" >GPT-3.5</div>
            <div className='chat-history' ref={chatHistoryRef}>
              {chatHistory.length === 0 ? (
                <NoChatsPlaceholder className="d-flex justify-content-center align-items-center"/> // Using the NoChatsPlaceholder component
              ) : (
                renderChatMessages() // Call the function to render messages
              )}
            </div>

          {/* {!leftsidebarOpen && (
            <Col className="chat-column p-3">
              <div className="chat-history" ref={chatHistoryRef}>
                <p>No chats loaded. Select a chat to view messages.</p>
              </div>
            </Col>
          )} */}
            {/* {sidebarOpen ? (
              <ChevronRight style={{position: 'absolute', right: '0%', top: '50%'}} size={24} color="#4b4b4b" onClick={toggleSidebar}></ChevronRight>
            ):(
            <ChevronLeft style={{position: 'absolute', right: '0%', top: '50%'}} size={24} color="#4b4b4b" onClick={toggleSidebar}></ChevronLeft>)} */}
            {/* className={`${sidebarOpen ? '':'fixed-input-container'}`} */}
            <div     
            className="fixed-input-container position-fixed" 
            style={{ 
              width: leftsidebarOpen ? 'calc(100% - 16.6667%)' : 'calc(100% - 6.667%)', 
              left: leftsidebarOpen ? '16.6667%' : '2.667%' 
            }}>
              <div className='buffer-input-form pb-1 mb-1' style={{ display: 'flex', alignItems: 'center'}}>
                <textarea
                  placeholder='Enter your anatomy related prompts'
                  value={question}
                  onChange={handleQuestionChange}
                  onKeyDown={handleKeyDown}
                  className='input-field-styling'
                  style={{ flex: 1, marginRight: '8px' }} // Add flex: 1 to fill available space and marginRight for spacing
                  disabled={isSending} // Disable the textarea when isSending is true
                  rows={textAreaRows} // Sets the dynamic rows for the textarea
                />
                <button
                  onClick={() => {
                    if (question.trim() !== '') { //Ensure the textarea is not empty
                      sendQuestionToAPI();
                    }
                  }}
                  className='question-button send-button' // Added send-button class for specific styling
                  disabled={isSending || question.trim() === ""} // Disable the button when isSending is true or textarea is empty
                >
                  {isSending ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    <Send color="white" size={24} /> // Use the Lucide "Send" icon
                  )}
                </button>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <span className="disclaimer text-muted small" style={{fontSize: '0.75rem'}}>
                  Anatbuddy may make mistakes. Treat it as a supplementary tool.
                </span>
              </div>
            </div>
          </Col>

          {/* <Col md={3} style={{ display: sidebarOpen ? 'block' : 'none', overflow:"hidden" }} className='model-stream-sidebar'>
            <div className='model-stream-header'>
              <h5> Anatomy Visualizer</h5>
            </div>
            <div className='mt-4 mx-2'>
              <canvas ref={babylonRef} id="renderCanvas"></canvas>
            </div>
            <div>
              <iframe title="streamingSess" className='i_Frame' width="100%" height="100%" frameBorder="0" />
            </div>
          </Col> */}
        </Container>

      {/* Conditionally render confetti */}
      {showConfetti && (
        <ConfettiBoom
        x={0.5}
        y={0.5}
        particleCount={100}
        shapeSize={12}
        colors={['#ff577f', '#ff884b', '#ffd384', '#fff9b0', '#3498db']}
        mode='fall'
        />
      )}
      
      {/* Modal for delete confirmation */}
      <Modal show={showModal} onHide={handleCancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this chat?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteChat}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Place this within the main return statement, alongside other modals */}
      <Modal show={showInstructions} onHide={handleCloseInstructions}>
        <Modal.Header closeButton>
          <Modal.Title>How to Use Anatbuddy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='text-start'>
            <p><b>Instructions</b></p>
            <ul>
              <li>To start a new chat, click the <b>"Anatbuddy" logo</b> at the top of the left sidebar.</li>
              <li><b>To begin</b>, enter your <b>anatomy-related prompts</b> in the input field and click the <b>send button</b> or the 'Enter' key.</li>
              <li>You can choose from the <b>provided starter prompts</b> to quickly start a conversation.</li>
              <li>Your <b>chat history</b> is automatically saved both in the <b>sidebar</b> for easy access and in the system's <b>database</b>.</li>
              <li>Click on any chat to continue where you left off.</li>
              <li>To delete a chat, <b>click the trash icon</b> next to the chat title in the sidebar.</li>
              <li>To rename a chat, <b>click the pencil icon</b> next to the chat title in the sidebar, <b>edit the title</b>, and <b>click 'Enter'</b>.</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseInstructions}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default GPT_35_RAG;
