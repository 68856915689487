import React, { useEffect } from 'react';
import NavbarComponent from './Navbar';
import { Container, Row, Col, Accordion, Button} from 'react-bootstrap'; // Import Bootstrap components

// Import styles
import '../styles/Dashboard.css';

// Import Images
import chatAnatomyDiagram from '../images/Diagrams/chat_anatomy_pipeline_diagram.png'
import finalUI from '../images/Diagrams/final_ui.png'

function Home() {
  // useEffect to set the document title
  useEffect(() => {
    document.title = 'About Anatbuddy';
  }, []);

  return (
    <div>
      <NavbarComponent />
      <div className="viewport-background">
        {/* Background Image with Title and Subtitle */}
        <div className="title-container">
          {/* Use p tags with custom classes for title and subtitle */}
          <p className="main-title">Anatbuddy</p>
          <p className="sub-title">Overview of the Project and Models</p>
        </div>
      </div>
      {/* Section below the image */}
      <Container fluid className="below-image-section">

        {/* //////////////////////////////// */}
        {/* Zeroth Row: Buttons to navigate to the other sections*/}
        {/* <Row className='justify-content-center text-center'>
          <Col sm={4} className="d-flex justify-content-center">
            <Button variant="outline-light" className="dashboard-navigation-button" href="#chatbot-instructions">What is & how to use Anatbuddy?</Button>
          </Col>
          <Col sm={4} className="d-flex justify-content-center">
            <Button variant="outline-light" className="dashboard-navigation-button" href="#how-work">How does it work?</Button>
          </Col>
          <Col sm={4} className="d-flex justify-content-center">
            <Button variant="outline-light" className="dashboard-navigation-button" href="#planned-models">Planned Models</Button>
          </Col>
        </Row> */}

        {/* //////////////////////////////// */}
        {/* First Row: Instructions on how to use the chatbot*/}
        <Row>
          <Col sm={12} className='content-title-col'>
            {/* <div>
              <hr className='below-image'></hr>
            </div> */}
            <p className='below-image-title' id="chatbot-instructions">Introduction to Anatbuddy: Overview and Usage Guide</p>
            <div>
              <hr className='below-image'></hr>
            </div>
          </Col>

          {/* Content Columns*/}
          <Col sm={10} className='content-column-wide'>
            <p className='content-paragraph'>
              Anatbuddy is a project that aims to create a multi-modal anatomy chatbot for the students of LKCMedicine. It aims to use Retrieval Augmented
              Generation (RAG) to accurately answer any text-based anatomy questions provided to it. We recognise that anatomy and anatomy education
              is a visual field of work so at the same time, we are working towards the generation of labelled 2D or 3D anatomy diagrams which 
              can be used in conjuction with the textual response from the LLM to aid in a student's understanding of a topic.
            </p>
            <p className='content-paragraph'>
              To use the Anatbuddy chatbot, you must first select a model from the navigation bar above. After this, you will be met with
              a menu which looks similar to ChatGPT. Simply write your question into the input field at the bottom of the screen and press 'Enter'
              to send your question to the bot. After this you will have to wait a  while for the chatbot to look through its sources and produce an answer.
            </p>
            <p className='content-paragraph'>
              Once this is complete, you will see that your chat now has a name on the sidebar. You can now continue chatting with the chatbot.
              If you hover over your chat's name in the sidebar, you can also delete or rename as you wish.

              If you want to start a new chat, simply click the "Anatbuddy" name or logo at the top of the sidebar. 
            </p>
          </Col>
        </Row>

        {/* //////////////////////////////// */}
        {/* Second Row: How the chatbot works*/}
        {/* <Row>
          <Col sm={12} className='content-title-col'>
            <div>
              <hr className='below-image'></hr>
            </div>
            <p className='below-image-title' id="how-work">How does it work?</p>
          </Col> */}
          {/* Content Columns*/}
          {/* <Col sm={10} className='content-column-wide content-column-wide-image'>
              <img src={chatAnatomyDiagram} className="responsive-image float-end imgshow"></img>
              <p>
                Anatbuddy works on a high level through LLM ensemble-ization. Multiple LLMs are engineered for different parts of the task
                and together can complete the whole task. As you can see with the figure on the right, our full process will have 3 main LLMs,
                though more might be implemented to check the quality of the outputted answers. Of course alongside this process, we have multiple
                technologies, such as databases that each store information required for the task of creating an accurate and helpful multi-modal answer to a medical question.
                <br></br><br></br>
                Now, let's get into the specific details, starting with the parts of Anatbuddy that have been completed thus far.
                <br></br><br></br>
                The process starts when a user asks a textual question through the Anatbuddy UI. This question will be sent to our
                database for conversation storage. The conversation history is then pulled from the database and fed to LLM #1, as seen on the right.
                Afterwards, we pass the same conversation history from the database to LLM #2, which summaries the whole conversation thus far into 
                a single statement/question. We also ensure the summarisation focuses on the latest topic of conversation. 
                <br></br><br></br>
                This summarised statement is then sent to an embedder, which turns it into a vector, a series of numbers which captures the relationships
                of the words from the statement. The embedding is then passed to our cloud vector store. This vector store is a database which holds the embedded data (called documents)
                from all the medical textbooks we are using for Retrieval Augmented Generation (RAG). We compare the newly created vector of the summarised conversation
                with the documents in the vector store to choose the most similar and helpful information. This means that if the user was asking the bot about questions related to the heart,
                we retrieve parts of the medical textbook with information related to the heart, which will be the most useful for LLM #1.
                <br></br><br></br>
                Then, we send the relevant RAG documents as context to the LLM and it uses them alongside the conversation history to output a medically accurate and helpful response.
                This response is then sent to the conversation database to be stored as the latest message in the conversation
                <br></br><br></br>
                Finally, we pull the full conversation history, including the latest LLM response from the database and display it to the user. 
                <br></br><br></br>
                That's our system as of now, but we are currently working on the 3D image generation part of the model. For this task, please refer to the right side 
                of the diagram above. We start by simultaneously passing the chat history to a 3rd LLM at the same time as we display the latest LLM response to the user.
                This third LLM will be given a tree-structure representation of a human body's 3D anatomy model and all its organs as JSON format. It will then use the conversation history
                to select a part of the JSON which is the most relevant to the current topic of conversation.
                <br></br><br></br>
                Then, the selected JSON will be used to select that part of our in-house full 3D anatomy model or individual 3D organ assets from another database.
                This will then be rendered on the UI alongside the text response from LLM #1.
                <br></br><br></br>
                That is the full pipeline for Anatbuddy's text and 3D labelled diagram response for an incoming question. We have not gone into depth on the 2D diagram generator
                as that is further in the future and is much more complex to build, requiring the training of multiple proprietary image generation models in house, as well as data collection
                from approved medical textbook sources, amongst various other challenges. By the end of the project development runtime, we wish to create a robust product with a UI and features similar to the below image: 
              </p>
            </Col>
            <Col sm={10} className='content-column-wide'>
                <img src={finalUI} className='responsive-image-wide'></img>
            </Col>
        </Row> */}

        {/* //////////////////////////////// */}
        {/* Third Row: Planned Models*/}
        {/* <Row>
          <Col sm={12} className='content-title-col'>
            <div>
              <hr className='below-image'></hr>
            </div>
            <p className='below-image-title' id="planned-models">Planned Models</p>
          </Col> */}

          {/* Content Columns*/}
          {/* <Col sm={10} className='content-column-wide'>
            <p className='content-paragraph'>
              Currently, we are using GPT-3.5 & 4 as our main LLMs for the project. However, this is not the only LLMs that we can or should use.
              Indeed we have looked into 2 other LLMs for the task, Meta AI's LLaMA-2 and Google's Med-PaLM-2 models. However, after experimenting
              with these models, we have found that GPT-3.5 and especially GPT-4 are better at answering questions in the medical domain more
              accurately when provided with sources for Retrieval Augmented Generation. As such, we are instead currently focusing on completing
              the core parts of the Anatbuddy project for now, mainly the figure generation aspect.
            </p>
            <p className='content-paragraph'>
              Once we have completed figure generation, we will go back to testing out these other LLMs.
            </p>
            <p>
              Below, you can read up on what each of these models can do, their benefits and why we did not use them in the end.
            </p>
          </Col>
        </Row> */}

        {/* Fourth Row: Collapsible Accordions for Models */}
        {/* <Row>
        <Accordion as={Col} sm={5} className='content-column' flush>
            <Accordion.Item eventKey="0">
                <Accordion.Header>LLaMA-2</Accordion.Header>
                <Accordion.Body>
                  <p>
                    LLaMA-2 is an open source model developed by Meta AI. It is able to be freely fine-tuned through many different
                    methods and services and can be easily depolyed via Amazon Web Services (AWS) Sagemaker Jumpstart. Once you get
                    access granted to the model from Meta (who gives access to any business with less than 700M monthly customers for free),
                    you can choose any of the 3 different model sizes:
                  </p>
                  <p>
                    <ul>
                      <li> LLaMA-2-7B</li>
                      <li> LLaMA-2-13B</li>
                      <li> LLaMA-2-70B</li>
                    </ul>

                    The ending number is the billions of parameters for each model. The LLaMA-2 model carries a lot of benefits. By being open source,
                    you can ensure your user's sensitive data and your RAG data will never be sent to a third party such as OpenAI.
                    Hence, you can more easily protect the data with your own security measures. Furthermore, it lets you easily tinker and 
                    fine-tune the model on top of RAG as you can access and change the model weights. This is in stark contrast to OpenAI's
                    black box. You have the flexibility choose the right sized model for your task, not having to pay for a model that is oversized and has long inference times
                    for a smaller or simpler task.
                  </p> 
                  <p>
                    The model is also adept at inference regardless of the size you choose. Even the tiny 7B parameter model returns great answers, though 
                    of course the larger the parameter count, the more accurate and useful it is.
                  </p>
                  <p>
                    However, this means you are not sending API calls to an externally hosted and deployed model. You will have to develop
                    manage and deploy the sever infrastructure to host the model yourself. We found that the cost of hosting the model on AWS was greater
                    than the cost of OpenAI's API Calls by a fair margin. This was for a few reasons. Even the largest LLaMA-2-70B model was not as 
                    accurate at answering medical domain knowledge questions when compared to GPT-3.5 when RAG was involved. However, the cost of hosting
                    this or even the much smaller the LLaMA-2-13B model was prohibitively expensive. Plus, it also has a tiny context length of 4096 tokens,
                    which would not hold much conversational data or RAG information.
                  </p>
                  <p>
                    Hence, we went with the higher accuracy and context token limit of GPT despite it being less flexible and slightly less secure.
                  </p>
                </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          
          <Accordion as={Col} sm={5} className='content-column' flush>
            <Accordion.Item eventKey="1">
              <Accordion.Header className='accordion-header'>Med-PaLM-2</Accordion.Header>
              <Accordion.Body>
                <p>
                  Med-PaLM-2 is an LLM developed by Google. More specifically, it is a domain adapted version of their PaLM-2 model, trained
                  on sources from the medical domain. We studied this model in the hopes that the medical domain knowledge it was trained on
                  would allow it to be even more accurate at answering student's anatomy questions
                </p>
                <p>
                  Med-PaLM-2 is sadly only available to Google Cloud customers and as such, is not open source like LLaMA-2. This is in
                  contrast with the PaLM-2 model it is based on, which is open-source. Regardless, Med-PaLM-2 provides a large array of
                  benefits. It makes use of new methods of prompting developed by the Google team such as <i>Ensemble Refinement</i> to do large scale
                  LLM-based quality checking on any answers it outputs, thus letting it improve the accuracy and consistency of its answers.
                </p>
                <p>
                  Furthermore, it has been trained on many open-souce medical datasets and has been shown to be the most accurate medical-LLM when testing
                  against the industry standard medical benchmarks.
                </p>
                <p>
                  However, we did not choose this model due to various reasons. First of all, it was trained heavily on USA-sources, and in their research,
                  it performed noticably worse when compared against Indian medical benchmarks. Hence, it might not have as much of a performance boost
                  when used to answer Singaporean medical questions in a Singapore context. Furthermore, it was seemingly over-using the data it was trained
                  with and not giving much importance to the RAG data from the medical textbooks provided to it. This would mean that RAG would not be effective with it. 
                </p>
                <p>
                  Finally, we found that calling the model via Google Cloud Platform would be more expensive than using OpenAI's GPT models and that GPT-4 was
                  still more accurate at answering the medical questions when RAG was involved. It also has a smaller token context limit than the GPT models
                  at 8,192. Due to all these reasons, we opted to use the more accurate and higher context GPT models instead.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row> */}
      </Container>
    </div>
  );
}

export default Home;
