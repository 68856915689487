// ProtectedRoute.js
import React from 'react';
import { useAuth } from './AuthContext'; // Use the custom hook from AuthContext
import { useNavigate } from 'react-router-dom'; // Use useNavigate hook for programmatic navigation
import SimpleModal from '../Modals/SimpleModal'; // Import the SimpleModal component

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate(); // Initialize navigate for redirection

  // If not authenticated, show the modal
  if (!isAuthenticated) {
    // Define the function to close the modal and navigate back to the login page
    const handleModalClose = () => {
      navigate('/'); // Programmatic navigation to the login page
    };

    return (
      <SimpleModal 
        message="Please log in to view this page."
        onClose={handleModalClose} // Pass the navigation function to the onClose prop
      />
    );
  }

  // If authenticated, render the children passed to ProtectedRoute
  return isAuthenticated ? children : null;
};

export default ProtectedRoute;