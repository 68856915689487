// Component used for W.I.P wbepages
// Import required components
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Construction } from 'lucide-react';
import '../../styles/temporary/WorkInProgress.css'; 

const WorkInProgress = ({ header, color = 'rgb(86, 0, 78)', children }) => {
  // style header and border
  const headerAndBorderStyle = {
    borderColor: color,
    color: color,
  };

  return (
    <Container fluid className="work-in-progress-container">
      <Row className="justify-content-md-center">
        <Col md={10} className="work-in-progress-column dashed-border" style={{ borderColor: color }}>
          <Construction color={color} size={50} className="icon" />
          <h1 className="header-text" style={headerAndBorderStyle}>{header}</h1>
          <div className="content">{children}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default WorkInProgress;