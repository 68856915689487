// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/Authentication/AuthContext";
import ProtectedRoute from "./components/Authentication/ProtectedRoutes";

// Import the different page components
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import GPT_35_RAG from "./components/LLMs/GPT/GPT_35_RAG";
import GPT_4_RAG from "./components/LLMs/GPT/GPT_4_RAG";
import GPT_4o_RAG from "./components/LLMs/GPT/GPT_4o_RAG";
import LLaMA_2_RAG from "./components/LLMs/LLaMA_2/LLaMA_2_RAG";
import Med_PaLM_2_RAG from "./components/LLMs/Med_PaLM_2/Med_PaLM_2_RAG";
import WebSocketsTest from "./components/Temporary/WebSocketsTest";
// import StreamedResponse from "./components/LLMs/GPT/Streamed_Response";

function App() {
  return (
    <AuthProvider> {/* Wrap routes with AuthProvider */}
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
            {/* GPT Routes */}
            <Route path="/gpt-3-5/rag" element={
              <ProtectedRoute>
                <GPT_35_RAG />
              </ProtectedRoute>
            } />
            <Route path="/gpt-3-5/rag/:chatUUID" element={
              <ProtectedRoute>
                <GPT_35_RAG /> 
              </ProtectedRoute>
            } />
            <Route path="/gpt-4/rag" element={
              <ProtectedRoute>
                <GPT_4_RAG />
              </ProtectedRoute>
            } />
            <Route path="/gpt-4/rag/:chatUUID" element={
              <ProtectedRoute>
                <GPT_4_RAG /> 
              </ProtectedRoute>
            } />
            <Route path="/gpt-4o/rag" element={
              <ProtectedRoute>
                <GPT_4o_RAG />
              </ProtectedRoute>
            } />
            <Route path="/gpt-4o/rag/:chatUUID" element={
              <ProtectedRoute>
                <GPT_4o_RAG /> 
              </ProtectedRoute>
            } />

            {/* Other LLM Routes */}
            <Route path="/llama-2/rag" element={
              <ProtectedRoute>
                <LLaMA_2_RAG />
              </ProtectedRoute>
            } />
            <Route path="/med-palm-2/rag" element={
              <ProtectedRoute>
                <Med_PaLM_2_RAG />
              </ProtectedRoute>
            } />

            {/* Websockets Testing*/}
            <Route path="/websockets-test" element={
              <ProtectedRoute>
                <WebSocketsTest />
              </ProtectedRoute>
            } />
            {/* <Route path="/streamed-response" element={
              <ProtectedRoute>
                <StreamedResponse />
              </ProtectedRoute>
            } /> */}

          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;