// SimpleModal.js
import React from 'react';
import '../../styles/modals/SimpleModal.css'; // Styling for the modal

const SimpleModal = ({ message, onClose }) => {

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-container" onClick={e => e.stopPropagation()}>
        <div className="modal-content">
          <p>{message}</p>
          <button onClick={onClose}>Close</button> {/* Call the onClose prop */}
        </div>
      </div>
    </div>
  );
};

export default SimpleModal;