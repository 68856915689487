import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "./Authentication/AuthContext"; // Import useAuth from AuthContext
import Spinner from 'react-bootstrap/Spinner'; // Import Spinner component for loading indication
import {ReactTyped} from 'react-typed'; // Import Typed component for typing effect
import "../styles/Login.css";

// Import required images
import logo from '../images/Global/true-chat-anatomy-logo.png'; // Import the logo

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false); // State to track login process
  const navigate = useNavigate();
  const { login } = useAuth(); // Destructure the login function from useAuth

  // useEffect to set the document title
  useEffect(() => {
    document.title = "Login | Anatbuddy";
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true); // Start the login process

    // Check if both username and password are entered
    if (!username || !password) {
      setLoginError("Username and password are required");
      setIsLoggingIn(false); // Stop the login process
      return;
    }

    try {
      // API call to AWS Lambda function for login
      const response = await fetch('https://vb9nim09ad.execute-api.ap-southeast-1.amazonaws.com/default/chat-anatomy-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      // Stop the login process
      setIsLoggingIn(false);

      // Handle different responses based on the API call
      if (response.status === 200) {
        // If login is successful, update the authentication state
        login(username); // Call the login function with the username
        navigate("/dashboard"); // Navigate to the Dashboard
      } else {
        // Set error messages based on the API response
        setLoginError(data.error || "Sorry! There was an error logging in. Please try again.");
      }
    } catch (error) {
      // Handle network or other unexpected errors
      setLoginError("Sorry! There was an error logging in. Please try again.");
      setIsLoggingIn(false); // Stop the login process
    }
  };

  return (
    <div className="login-page">
      <div className="login-background">{/* Background Image */}</div>
      <div className="login-container">
        <div className="login-box">
          <div className="login-header-content">
            <img src={logo} alt="Chat Anatomy Logo" className="login-logo" />
            <h2 className='login-header-title'>
              <ReactTyped
                strings={[
                  "Anatbuddy"
                ]}
                typeSpeed={80}
              />
            </h2>
          </div>
          <h3 className="login-title">Login</h3>
          <form className="login-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-form-elements"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-form-elements"
            />
            <button type="submit" className="login-button" disabled={isLoggingIn}>
              {isLoggingIn ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                "Login"
              )}
            </button>
            {loginError && <p className="text-center">{loginError}</p>}
            <div className="signup-link">
              Don't have an account? <Link to="/sign-up">Sign up!</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
    // <div className="login-page">
    //   <div className="login-background">
    //     {/* Background Image */}
    //   </div>
    //   <div>
    //     <br />
    //     <h2 className="login-center">Login</h2>
    //     <h3 className="text-center">And solve your anatomy questions with Anatbuddy!</h3>
    //     <br />
    //   </div>
    //   <br />
    //   <div className="login-container">
    //     <form className="login-form" onSubmit={handleSubmit}>
    //       <input
    //         type="text"
    //         placeholder="Username"
    //         value={username}
    //         onChange={(e) => setUsername(e.target.value)}
    //         className="login-form-elements"
    //       />
    //       <br />
    //       <input
    //         type="password"
    //         placeholder="Password"
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //         className="login-form-elements"
    //       />
    //       <br />
    //       <button type="submit" className="login-button" disabled={isLoggingIn}>
    //         {isLoggingIn ? (
    //           <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
    //         ) : (
    //           "Login"
    //         )}
    //       </button>
    //       {/* Display error messages */}
    //       {loginError && <p className="text-center">{loginError}</p>}
    //       {/* Hyperlink to SignUp page */}
    //       <div className="signup-link">
    //         <Link to="/sign-up">Don't have an account? Sign up!</Link>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );
}

export default Login;