import React, { useState, createContext, useContext } from 'react';

// Create a Context for authentication and user information
export const AuthContext = createContext();

// Custom hook for easy access to the AuthContext
export const useAuth = () => useContext(AuthContext);

// Provider component to wrap around the app, providing authentication state and user info
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track authentication status
  const [username, setUsername] = useState(null); // State to store the current user's username

  // Function to call when the user logs in
  const login = (user) => {
    setIsAuthenticated(true); // Set authenticated status to true
    setUsername(user); // Update the username state with the logged-in user's name
  };

  // Function to call when the user logs out
  const logout = () => {
    setIsAuthenticated(false); // Set authenticated status to false
    setUsername(null); // Clear the username state
  };

  // The value provided to descendants of this Provider
  return (
    <AuthContext.Provider value={{ isAuthenticated, username, login, logout }}>
      {children} {/* Render children components passed into this Provider */}
    </AuthContext.Provider>
  );
};