// Import required packages
import React from 'react';
import NavbarComponent from '../../Navbar';
import WorkInProgress from '../../Temporary/WorkInProgress';

function LLaMA_2_RAG() {
    return (
        <div>
            <NavbarComponent />
            <WorkInProgress header="Hello There!" color="rgb(86, 0, 78)">
                <p>
                    The LLaMA-2 Section is still being worked on. If you go back to the Home Page, you can see our research into the various
                    LLMs and where we wish to take the project in the coming months. 
                </p>
                <p>
                    As we have found the GPT models (especially GPT-4) to be more accurate than LLaMA-2 for the medical domain with Retrieval Augmented Generation,
                    we want to prioritise the development of the 2D and 3D figure generation of the Chat Anatomy project for now. 
                </p>
                <p>
                    For now, please try Chat Anatomy via the GPT tab in the navigation bar above!
                </p>
                <p>
                    Thanks!
                </p>
            </WorkInProgress>
        </div>
    )
}

export default LLaMA_2_RAG;