import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../styles/Login.css";
import {ReactTyped} from 'react-typed'; // Import Typed component for typing effect

// Import required images
import logo from '../images/Global/true-chat-anatomy-logo.png'; // Import the logo

function SignUp() {
  // State hooks for managing form inputs and error messages
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signupError, setSignupError] = useState("");
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    number: false,
    specialChar: false,
  });

  // Set the document title using useEffect
  useEffect(() => {
    document.title = "Sign Up | Anatbuddy";
  }, []);

  const navigate = useNavigate(); // Initialize useNavigate for redirecting after signup

  // Update password criteria states based on the current password
  useEffect(() => {
    const lengthCheck = /.{8,}/; // At least 8 characters
    const numberCheck = /\d/; // Contains a number
    const specialCharCheck = /[!@#$%^&*()\-_=+{};:,<.>]/; // Contains a special character

    setPasswordCriteria({
      length: lengthCheck.test(password),
      number: numberCheck.test(password),
      specialChar: specialCharCheck.test(password),
    });
  }, [password]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the passwords entered match
    if (password !== confirmPassword) {
      setSignupError("Passwords do not match.");
      return;
    }

    // Call the API to attempt signup
    try {
      const response = await fetch('https://d0gdtw4lbk.execute-api.ap-southeast-1.amazonaws.com/default/chat-anatomy-signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      // Handle responses from the API
      if (data.error) {
        if (data.error === "Username already exists") {
          setSignupError("Username already exists. Please choose a different username.");
        } else if (data.error === "Password does not meet complexity requirements") {
          setSignupError("Password does not meet complexity requirements. Please create a stronger password.");
        } else {
          setSignupError("Sorry! There was an error signing up. Please try again soon.");
        }
      } else {
        // Redirect to login page on successful signup
        navigate("/");
      }
    } catch (error) {
      // Handle network or other unexpected errors
      setSignupError("Sorry! There was an error signing up. Please try again soon.");
    }
  };

  return (
    <div className="login-page">
      <div className="login-background">{/* Background Image */}</div>
      <div className="login-container">
        <div className="login-box">
          <div className="login-header-content">
            <img src={logo} alt="Chat Anatomy Logo" className="login-logo" />
            <h2 className='login-header-title'>
              <ReactTyped
                strings={[
                  "Anatbuddy"
                ]}
                typeSpeed={70}
                backSpeed={60}
                backDelay={2500}
                loop
              />
            </h2>
          </div>
          <h3 className="login-title">Sign Up</h3>
          <form className="login-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-form-elements"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-form-elements"
            />
            <ul className="password-criteria">
              <li style={{ color: passwordCriteria.length ? 'green' : 'grey' }}>Password must be 8 characters or longer</li>
              <li style={{ color: passwordCriteria.number ? 'green' : 'grey' }}>Password must have a number</li>
              <li style={{ color: passwordCriteria.specialChar ? 'green' : 'grey' }}>Password must have a special character</li>
            </ul>
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="login-form-elements"
            />
            <button type="submit" className="login-button">
              Sign Up
            </button>
            <div className="signup-link">
              Already have an account? <Link to="/">Login!</Link>
            </div>
            {/*<button type="button" className="return-to-login-button" onClick={() => navigate("/")}>
              Return to Login
            </button> */}
          </form>
          {signupError && <p>{signupError}</p>}
        </div>
      </div>
    </div>
    // <div>
    //   <div className="login-background">
    //     {/* Background image */}
    //   </div>
    //   <div>
    //     <br />
    //     <h2 className="login-center">Sign Up</h2>
    //     <h3 className="text-center">Join Anatbuddy Today!</h3>
    //     <br />
    //   </div>
    //   <br />
    //   <div className="login-container">
    //     <form className="login-form" onSubmit={handleSubmit}>
    //       {/* Username input field */}
    //       <input
    //         type="text"
    //         placeholder="Username"
    //         value={username}
    //         onChange={(e) => setUsername(e.target.value)}
    //         className="login-form-elements"
    //       />
    //       <br />
    //       {/* Password input field */}
    //       <input
    //         type="password"
    //         placeholder="Password"
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //         className="login-form-elements"
    //       />
    //       <br />
    //       {/* Reactive list for password requirements */}
    //       <ul>
    //         <li style={{ color: passwordCriteria.length ? 'green' : 'grey' }}>Password must be 8 characters or longer</li>
    //         <li style={{ color: passwordCriteria.number ? 'green' : 'grey' }}>Password must have a number</li>
    //         <li style={{ color: passwordCriteria.specialChar ? 'green' : 'grey' }}>Password must have a special character</li>
    //       </ul>
    //       {/* Confirm password input field */}
    //       <input
    //         type="password"
    //         placeholder="Confirm Password"
    //         value={confirmPassword}
    //         onChange={(e) => setConfirmPassword(e.target.value)}
    //         className="login-form-elements"
    //       />
    //       <br />
    //       {/* Signup button */}
    //       <button type="submit" className="login-button">
    //         Sign Up
    //       </button>
    //       <br />
    //       {/* Return to Login button */}
    //       <button type="button" className="return-to-login-button" onClick={() => navigate("/")}>
    //         Return to Login
    //       </button>
    //     </form>
    //     {/* Display any signup error messages */}
    //     {signupError && <p>{signupError}</p>}
    //   </div>
    // </div>
  );
}

export default SignUp;