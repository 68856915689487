import React, { useState, useEffect } from 'react';
import NavbarComponent from '../Navbar';
import WorkInProgress from './WorkInProgress';

function WebSocketsTest() {
    const [seconds, setSeconds] = useState(1);
    const [latencies, setLatencies] = useState([]);
    const [averageTimes, setAverageTimes] = useState({
        clientToServer: null,
        serverToClient: null,
        totalRoundTrip: null,
    });

    // Handle WebSocket connection
    useEffect(() => {
        const ws = new WebSocket('ws://13.213.197.123:8080');
        const sendTimes = {};

        const startTest = () => {
            setLatencies([]);
            for (let i = 1; i <= seconds; i++) {
                for (let j = 1; j <= 24; j++) {
                    setTimeout(() => {
                        const message = `${i}-${j}`;
                        const timestamp = new Date().getTime();
                        sendTimes[message] = timestamp;
                        console.log(`Sending ${message}: `, new Date(timestamp).toISOString());
                        ws.send(message);
                    }, 1000 * (i - 1) + (1000 / 24) * j);
                }
            }
        };

        ws.onopen = function() {
            console.log('Connected to the server');
            startTest();
        };

        ws.onmessage = function(event) {
            const response = JSON.parse(event.data);
            const clientReceiveTime = new Date().getTime();
            const serverReceiveTime = new Date(response.timestamp).getTime();
            const clientSendTime = sendTimes[response.data];

            const clientToServerLatency = serverReceiveTime - clientSendTime;
            const serverToClientLatency = clientReceiveTime - serverReceiveTime;
            const totalLatency = clientReceiveTime - clientSendTime;

            console.log(`Received: ${response.data}, Server Timestamp: ${response.timestamp}`);
            console.log(`Client to Server Latency: ${clientToServerLatency} ms`);
            console.log(`Server to Client Latency: ${serverToClientLatency} ms`);
            console.log(`Total Latency: ${totalLatency} ms`);

            setLatencies((latencies) => [
                ...latencies,
                { clientToServerLatency, serverToClientLatency, totalLatency },
            ]);
        };

        ws.onerror = function(error) {
            console.error('WebSocket Error:', error);
        };

        ws.onclose = function() {
            console.log('WebSocket is closed now.');
        };

        return () => {
            ws.close();
        };
    }, [seconds]); // Re-run the effect only if `seconds` changes

    // Handle latency tracking
    useEffect(() => {
        if (latencies.length === seconds * 24) {
            const totalClientToServer = latencies.reduce((acc, cur) => acc + cur.clientToServerLatency, 0);
            const totalServerToClient = latencies.reduce((acc, cur) => acc + cur.serverToClientLatency, 0);
            const totalRoundTrip = latencies.reduce((acc, cur) => acc + cur.totalLatency, 0);

            setAverageTimes({
                clientToServer: (totalClientToServer / latencies.length).toFixed(2),
                serverToClient: (totalServerToClient / latencies.length).toFixed(2),
                totalRoundTrip: (totalRoundTrip / latencies.length).toFixed(2),
            });
        }
    }, [latencies, seconds]);

    return (
        <div>
            <NavbarComponent />
            <WorkInProgress header="WebSocket API Test" color="rgb(86, 0, 78)">
                <input
                    type="number"
                    value={seconds}
                    onChange={(e) => setSeconds(Math.min(60, Math.max(1, parseInt(e.target.value))))}
                    min="1"
                    max="60"
                />
                <button onClick={() => setSeconds((prev) => prev)}>Send Messages</button>
                <div>
                    {averageTimes.clientToServer && <div>Average Client to Server Latency: {averageTimes.clientToServer}ms</div>}
                    {averageTimes.serverToClient && <div>Average Server to Client Latency: {averageTimes.serverToClient}ms</div>}
                    {averageTimes.totalRoundTrip && <div>Average Total Round Trip Latency: {averageTimes.totalRoundTrip}ms</div>}
                </div>
            </WorkInProgress>
        </div>
    );
}

export default WebSocketsTest;